<template>
  <div>
    <section
      :class="[
        'banner',
        { 'banner--image-right': imagePosition === 'right' }
      ]"
      :style="{backgroundImage: `url(${backgroundImage}), url(${backgroundImageLocal})`}"
    >
      <!-- eslint-disable-next-line -->
      <div
        v-if="data.image"
        class="banner__image"
        :style="{backgroundImage: `url(${image})`}"
      />
      <v-container class="banner__container">
        <div class="banner__content">
          <div class="banner__pretitle">
            {{ $t(data.pretitle) }}
          </div>
          <div class="banner__title">
            {{ $t(data.title) }}
          </div>
          <!-- eslint-disable-next-line -->
          <div class="banner__subtitle">
            {{ $t(data.subtitle) }}
          </div>
          <a
            :href="buttonLink"
            target="_blank"
            class="banner__button"
          >
            {{ $t(data.button.label) }}
          </a>
        </div>
      </v-container>
    </section>
    <section
      :class="[
        'banner-mobile',
        { 'banner--image-right': imagePosition === 'right' }
      ]"
      :style="{backgroundImage: `url(${backgroundImageLocalMobile})`}"
    >
      <!-- eslint-disable-next-line -->
    <div
        v-if="data.image"
        class="banner__image"
        :style="{backgroundImage: `url(${imageMobile}), url(${imageLocalMobile})`}"
      >
      </div>
      <v-container class="banner__container">
        <div class="banner__content">
          <div class="banner__pretitle">
            {{ $t(data.pretitle) }}
          </div>
          <div class="banner__title">
            {{ $t(data.title) }}
          </div>
          <!-- eslint-disable-next-line -->
          <div class="banner__subtitle">
            {{ $t(data.subtitle) }}
          </div>
          <a
            :href="buttonLink"
            target="_blank"
            class="banner__button"
          >
            {{ $t(data.button.label) }}
          </a>
        </div>
      </v-container>
    </section>
  </div>
</template>

<script>
import { getLinkByEvent } from '@/utils/mediaHelpers'
import { getThemePath } from '@/utils/common'

export default {
  name: 'Banner',

  props: {
    imagePosition: {
      type: String,
      default: () => 'left'
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    image () {
      return getLinkByEvent(`assets/img/${this.getThemePath()}/${this.data.image}`)
    },

    imageMobile () {
      return this.data.imageMobile && getLinkByEvent(`assets/img/${this.getThemePath()}/${this.data.imageMobile}`)
    },

    imageLocal () {
      return require(`@/assets/img/${this.getThemePath()}/${this.data.image}`)
    },

    imageLocalMobile () {
      return this.data.imageMobile && require(`@/assets/img/${this.getThemePath()}/${this.data.imageMobile}`)
    },

    backgroundImage () {
      return getLinkByEvent(`assets/img/${this.getThemePath()}/${this.data.backgroundImage}`)
    },

    backgroundImageMobile () {
      return this.data.backgroundImageMobile && getLinkByEvent(`assets/img/theme-forum-22/${this.data.backgroundImageMobile}`)
    },

    backgroundImageLocal () {
      return require(`@/assets/img/${this.getThemePath()}/${this.data.backgroundImage}`)
    },

    backgroundImageLocalMobile () {
      return this.data.backgroundImageMobile && require(`@/assets/img/theme-forum-22/${this.data.backgroundImageMobile}`)
    },

    buttonLink () {
      return this.$t(this.data.button.link)
    }
  },

  methods: {
    getThemePath
  }
}
</script>
