<template>
  <section class="section-mp-speakers">
    <div class="mp-speakers-container">
      <v-container>
        <div class="row">
          <div class="col-12">
            <div class="mp-speakers-top-block">
              <div class="mp-speakers-top-left">
                <div class="sect-count">
                  .04
                </div>

                <router-link
                  :to="{name: 'schedule', hash: '#shcedule_speakers'}"
                >
                  <h2
                    style="max-width: 340px;"
                    class="section-title"
                  >
                    {{ $t('forum speakers') | upperFirst }}
                  </h2>
                </router-link>
              </div>

              <div class="mp-speakers-top-right">
                <div class="mp-speakers-all-link-block d-none d-md-block">
                  <router-link :to="{name: 'schedule', hash: '#shcedule_speakers'}" class="mp-speaker-all-link">
                    {{ $t('all speakers') | upperFirst }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-container>

      <v-container class="speaker-slider-block-container">
        <div class="speaker-slider-block">
          <Slick
            ref="carousel"
            :options="sliderSettings"
            class="speaker-slider slider"
          >
            <div
              v-for="(item, index) in items"
              :key="'' + index + item.name"
              class="speaker-slide slide"
            >
              <div class="speaker-item">
                <div class="speaker-item__img-block">
                  <router-link :to="`/profile-speaker/${item.slug}`" class="speaker-item__img">
                    <img
                      :src="getLinkByFileName('speakers', 'large', item.photo)"
                      :alt="item.name"
                      @error="handleError"
                    >
                  </router-link>

                  <div class="speaker-item__soc-links">
                    <a
                      v-if="item.instagram"
                      :href="item.instagram"
                      target="_blank"
                      class="speaker-item__soc-link speaker-item__soc-link--instagram"
                    />

                    <a
                      v-if="item.facebook"
                      :href="item.facebook"
                      target="_blank"
                      class="speaker-item__soc-link speaker-item__soc-link--facebook"
                    />

                    <a
                      v-if="item.twitter"
                      :href="item.twitter"
                      target="_blank"
                      class="speaker-item__soc-link speaker-item__soc-link--twitter"
                    />
                  </div>
                </div>

                <div class="speaker-item__content">
                  <div class="speaker-item__name">
                    <router-link :to="`/profile-speaker/${item.slug}`">
                      {{ item.name }}
                    </router-link>
                  </div>

                  <div class="speaker-item__status">
                    {{ item.position }}
                  </div>
                </div>
              </div>
            </div>
          </Slick>
        </div>
      </v-container>

      <v-container>
        <div class="mp-speakers-all-link-block-mobile-wrapper">
          <div class="mp-speakers-all-link-block">
            <router-link :to="{name: 'schedule', hash: '#shcedule_speakers'}" class="mp-speaker-all-link">
              {{ $t('all speakers') | upperFirst }}
            </router-link>
          </div>
        </div>
      </v-container>
    </div>
  </section>
</template>

<script>
import Slick from 'vue-slick'
import { getLinkByFileName } from '@/utils/mediaHelpers'

export default {
  components: { Slick },

  props: {
    items: {
      type: Array,
      default: () => ([])
    }
  },

  data: () => ({
    sliderSettings: {
      slidesToShow: 4,
      arrows: true,
      dots: false,
      infinite: false,
      useTransform: false,
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            arrows: true,
            slidesToShow: 3
          }
        },
        {
          breakpoint: 992,
          settings: {
            arrows: true,
            slidesToShow: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            variableWidth: true,
            slidesToShow: 1
          }
        }
      ]
    }
  }),

  watch: {
    items () {
      const currIndex = this.$refs.carousel.currentSlide()

      this.$refs.carousel.destroy()
      this.$nextTick(() => {
        this.$refs.carousel.create()
        this.$refs.carousel.goTo(currIndex, true)
      })
    }
  },

  methods: {
    getLinkByFileName,
    handleError (e) {
      // e.target.src = getLinkByFileName('/assets/img/theme/person-plug.png')
    }
  }
}
</script>

<style lang="scss">
.speaker-item__img {
  background: var(--person-plug);
  background-size: cover;
}

.section-mp-speakers {
  .slick-arrow.slick-disabled {
    visibility: hidden;
  }
}
</style>
